import React from "react"
import Layout from "../templates/Layout"
import Wrapper from "../templates/Wrapper"
import Section from "../templates/Section"
import "../styles/http404.scss"

const Http404 = () => (
  <Layout pageTitle="404: Page not found">
    <Wrapper wrapperType="section">
      <Section header="404: Page not found">
        <div className="http-404">
          <h1>Header 1</h1>
          <h2>Header 2</h2>
          <h3>Header 3</h3>
          <h4>Header 4</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
            perferendis omnis reprehenderit, quia commodi porro iure officia. Ea
            numquam temporibus, ab obcaecati illo quisquam natus consequatur
            officiis pariatur rem debitis!
          </p>
          <p>
            <strong>strong</strong>
          </p>
          <p>
            <i>italic</i>
          </p>
          <p>
            <a href="/">hyperlink</a>
          </p>
        </div>
      </Section>
    </Wrapper>
  </Layout>
)

export default Http404
